<template>
  <div class="register">
    <div class="register-border">
      <div class="flex flex-end">
        <!-- <i class="el-icon-close"></i> -->
      </div>
      <div
        class="flex register-type flex-between"
        :style="type == 1 ? 'justify-content: center;' : ''"
      >
        <div
          v-for="(item, index) in typeList"
          :key="index"
          @click="typeListButton(index)"
          :class="
            typeListIndex == index && index == 0
              ? 'login-content-type-li'
              : 'login-content-type-li1'
          "
        >
          <div
            class="type-name"
            :class="
              typeListIndex == index
                ? 'login-content-type-li-optfor'
                : 'login-content-type-li-optfor1'
            "
            v-if="typeListIndex == 0"
          >
            {{ item.name }}{{ type == 0 ? "登录" : "注册" }}
          </div>
          <div
            class="login-content-type-li-text"
            :class="
              typeListIndex == index
                ? 'login-content-type-li1-optfor'
                : 'login-content-type-li1-optfor1'
            "
            v-else
          >
            {{ item.name }}{{ type == 0 ? "登录" : "注册" }}
          </div>
        </div>
      </div>
      <div class="register-input">
        <el-input :placeholder="'请输入' + typeText" v-model="mail">
          <div
            slot="prefix"
            style="height: 100%; display: flex; align-items: center"
          >
            <img
              :src="
                typeListIndex == 1
                  ? 'https://cdn.bubbleplan.cn/static/login/Frame95482x.png'
                  : 'https://cdn.bubbleplan.cn/static/login/icon2x.png'
              "
              alt=""
              style=""
              class="register-input-imgew"
            />
          </div>
        </el-input>
        <div class="flex" style="margin-top: 21px">
          <el-input :placeholder="'请输入验证码'" v-model="verificationCode">
            <div
              slot="prefix"
              style="height: 100%; display: flex; align-items: center"
            >
              <img
                src="https://cdn.bubbleplan.cn/static/login/lock2x.png"
                alt=""
                class="register-input-imgew"
              />
            </div>
          </el-input>
          <el-button type="success" @click="getcode()" v-if="isgetcode">{{
            text
          }}</el-button>
          <!-- <el-button type="success" v-else disabled>{{ countdown
                        }}秒</el-button> -->
          <div class="buttonwerw" v-else>{{ countdown }}秒</div>
        </div>
        <div class="register-text" v-if="type == 0">
          没有账号？<span
            style="color: #de56c8"
            class="register-text-w"
            @click="typeButton(1)"
            >立即注册</span
          >
        </div>
        <div class="register-text" v-if="type == 1">
          已有账号<span
            style="color: #de56c8"
            class="register-text-w"
            @click="typeButton(0)"
            >立即登录</span
          >
        </div>
      </div>
      <div class="flex register-agreement items-center flex-center">
        <img
          :src="
            isPitchon
              ? 'https://cdn.bubbleplan.cn/static/login/Group103422x.png'
              : 'https://cdn.bubbleplan.cn/static/login/Group103262x.png'
          "
          alt=""
          class="register-agreement-img"
          @click="isPitchon = !isPitchon"
        />
        <div>
          阅读并接受
          <span class="register-agreement-text" @click="userAgreement()"
            >用户协议</span
          >
          和
          <span class="register-agreement-text" @click="privacyAgreement()"
            >隐私政策</span
          >
        </div>
      </div>

      <div class="register-button">
        <el-button type="success" :disabled="isdisabled" @click="nextstep()">{{
          type == 0 ? "登录" : "注册"
        }}</el-button>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="base64ImageShow"
      width="30%"
      @close="closeBase64"
      :show-close="false"
    >
      <div class="base64ImageClass">
        <div class="flex">
          <el-input v-model="validate" placeholder="请输入验证码">
            <div
              slot="prefix"
              style="height: 100%; display: flex; align-items: center"
            >
              <img
                src="https://cdn.bubbleplan.cn/static/login/Frame2x.png"
                alt=""
                style="width: 29px; height: 29px"
                class="register-input-imgew"
              />
            </div>
          </el-input>
          <div class="flex" style="margin-left: 10px; align-items: center">
            <img
              :src="base64Image"
              alt="Base64 Image"
              class="base64ImageClass-img"
            />
            <img
              src="https://cdn.bubbleplan.cn/static/home/refresh.png"
              alt=""
              style="width: 28px; height: 28px; margin-left: 10px"
              class="base64ImageClass-refresh"
              @click="getImgBase64(1)"
            />
          </div>
        </div>
        <div
          style="margin-top: 43px; justify-content: space-evenly"
          class="flex"
        >
          <div class="base64ImageClass-button" @click="closeBase64()">取消</div>
          <div @click="countersign()" class="base64ImageClass-button">确认</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      typeList: [
        {
          name: "邮箱",
        },
        {
          name: "手机号",
        },
      ],
      typeListIndex: 0,
      typeText: "邮箱",
      mail: "",
      verificationCode: "",
      text: "获取",
      countdown: 60,
      isgetcode: true,
      timerInterval: null,
      type: 0, //0登录 1注册
      isdisabled: true,
      secondsTimer: null,
      base64Image: null,
      base64ImageShow: false,
      captchaKey: null,
      validate: "",
      isPitchon: false,
    };
  },
  watch: {
    mail(newValue) {
      if (newValue && this.verificationCode != "") {
        this.isdisabled = false;
      } else {
        this.isdisabled = true;
      }
    },
    verificationCode(newValue) {
      if (newValue && this.mail) {
        this.isdisabled = false;
      } else {
        this.isdisabled = true;
      }
    },
  },
  methods: {
    userAgreement() {
      this.$router.push("/login/userAgreement");
    },
    privacyAgreement() {
      this.$router.push("/login/privacyAgreement");
    },
    closeBase64() {
      this.base64ImageShow = false;
      this.validate = "";
    },
    countersign() {
      if (!this.validate) {
        this.$message.error("请输入验证码");
        return;
      }
      // this.base64ImageShow = false;
      // this.validate = "";

      if (this.typeListIndex == 0) {
        this.countdown = 60;
        this.sendMail();
      } else {
        this.countdown = 60;
        this.sendSms();
      }
    },

    //获取图片验证码
    async getImgBase64(type) {
      const params = {
        key: this.mail,
      };
      const { data, code } = await this.$ajax.getImgBase64(params);
      if (code == 200) {
        this.base64Image = data.base64Image;
        this.captchaKey = data.captchaKey;
        if (type != 1) {
          this.base64ImageShow = true;
        }
      }
    },
    //注册
    async registerByVerificationCode() {
      const params = {
        mail: this.mail,
        verificationCode: this.verificationCode,
        device: "H5",
      };

      const { data, code } = await this.$ajax.registerByVerificationCode(
        params
      );
      console.log(data);
      if (code == 200) {
        this.usernameUser(data);
        setTimeout(() => {
          if (this.$route.path !== "/user") {
            this.$router.push("/user");
          }
        }, 1000);
      }

      console.log(code);
    },
    //邮箱登录
    async loginByVerificationCode() {
      const params = {
        // mail: this.mail,
        verificationCode: this.verificationCode,
        device: "H5",
      };
      if (this.typeListIndex == 0) {
        params.mail = this.mail;
      } else {
        params.userTel = this.mail;
      }
      const { data, code } = await this.$ajax.loginByVerificationCode(params);
      if (code == 200) {
        this.usernameUser(data);
        setTimeout(() => {
          if (this.$route.path !== "/user") {
            this.$router.push("/user");
          }
        }, 1000);
      }
    },
    //登录或注册
    nextstep() {
      if (!this.isPitchon) {
        this.$message.error("请勾选用户协议和隐私协议");
        return;
      }
      if (this.type == 1) {
        this.registerByVerificationCode();
      } else {
        this.loginByVerificationCode();
      }
    },

    //登录注册切换
    typeButton(type) {
      this.type = type;
      this.mail = "";
      this.verificationCode = "";
      this.isPitchon = false;
      if (this.type == 1) {
        this.typeListIndex = 0;
        this.typeText = "邮箱";
        this.typeList = [
          {
            name: "邮箱",
          },
        ];
      } else {
        this.typeList = [
          {
            name: "邮箱",
          },
          {
            name: "手机号",
          },
        ];
      }
    },
    //切换
    typeListButton(index) {
      this.mail = "";
      this.verificationCode = "";
      this.typeListIndex = index;
      if (index == 0) {
        this.typeText = "邮箱";
      } else {
        this.typeText = "手机号";
      }
    },
    getcode() {
      // console.log(this.$reg.email1);
      if (this.typeListIndex == 0) {
        if (!this.$reg.email1.test(this.mail)) {
          this.$message.error("请输入正确邮箱地址");
          return;
        }
        this.getImgBase64();
        return;
        this.countdown = 60;
        this.sendMail();
      } else {
        if (!this.$reg.phone.test(this.mail)) {
          this.$message.error("请输入正确手机号");
          return;
        }
        this.getImgBase64();
        return;
        this.countdown = 60;
        this.sendSms();
      }
    },
    updateTimer() {
      if (this.countdown == 0) {
        clearInterval(this.timerInterval);
        // 验证码过期的逻辑
        this.text = "获取";
        this.isgetcode = true;
      } else {
        this.countdown--;
      }
    },

    //发送邮箱验证码
    async sendMail() {
      const params = {
        mail: this.mail,
        key: this.captchaKey,
        validate: this.validate,
      };
      const { data, code } = await this.$ajax.sendMail(params);
      if (code == 200) {
        this.base64ImageShow = false;
        this.timerInterval = setInterval(this.updateTimer, 1000);
        this.isgetcode = false;
      }
      this.validate = "";
    },
    //发送手机验证码
    async sendSms() {
      const params = {
        userTel: this.mail,
        key: this.captchaKey,
        validate: this.validate,
      };
      const { data, code } = await this.$ajax.sendSms(params);
      if (code == 200) {
        this.base64ImageShow = false;
        this.timerInterval = setInterval(this.updateTimer, 1000);
        this.isgetcode = false;
        // this.validate = "";
      }
      this.validate = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.register {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/login/logo_bgc.png")
    no-repeat;
  background-size: 100% 100%;
  padding-top: 180px;

  .register-border {
    margin: auto;
    width: 525px;
    height: 505px;
    flex-shrink: 0;
    border-radius: 20px;
    background: url("https://cdn.bubbleplan.cn/static/login/Group103512x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 56px 26.88px;

    .el-icon-close {
      color: #64849a;
      font-size: 24px;
    }
  }

  .register-type {
    // margin-top: 31px;
    padding: 0 62px;

    .login-content-type-li {
      font-size: 28px;

      &-text {
      }
      &-optfor:hover {
        cursor: pointer;
      }
      &-optfor1:hover {
        cursor: pointer;
      }

      &-optfor {
        width: 167px;
        height: 71px;
        line-height: 71px;
        text-align: center;
        background: url("https://cdn.bubbleplan.cn/m_bubble/mine/Vect12313or6062x.png")
          no-repeat;
        background-size: 100% 100%;
        color: #574352;
        font-family: "TsangerYuMo";
        font-size: 24px;
        font-weight: 800;
        margin-right: 15px;
      }

      &-optfor1 {
        width: 167px;
        height: 71px;
        line-height: 71px;
        text-align: center;
        background: url("https://cdn.bubbleplan.cn/m_bubble/mine/Vec123131tor6542x.png")
          no-repeat;
        background-size: 100% 100%;
        color: #776559;
        font-family: "TsangerYuMo";
        font-size: 24px;
        font-weight: 800;
        margin-left: 15px;
      }
    }
    .login-content-type-li1 {
      font-size: 28px;

      &-text {
      }
      &-optfor:hover {
        cursor: pointer;
      }
      &-optfor1:hover {
        cursor: pointer;
      }

      &-optfor {
        width: 167px;
        height: 71px;
        line-height: 71px;
        text-align: center;
        background: url("https://cdn.bubbleplan.cn/m_bubble/mine/Vec12312tor6542x.png")
          no-repeat;
        background-size: 100% 100%;
        color: #574352;
        font-family: "TsangerYuMo";
        font-size: 24px;
        font-weight: 800;
        margin-left: 15px;
      }

      &-optfor1 {
        width: 167px;
        height: 71px;
        line-height: 71px;
        text-align: center;
        background: url("https://cdn.bubbleplan.cn/m_bubble/mine/Ve123ctor6062x.png")
          no-repeat;
        background-size: 100% 100%;
        color: #776559;
        font-family: "TsangerYuMo";
        font-size: 24px;
        font-weight: 800;
        margin-right: 15px;
      }
    }
    .type-name {
      // color: #d0d0d0;
      // font-size: 24px;
    }

    .subscript {
      width: 29px;
      height: 4px;
      flex-shrink: 0;
      border-radius: 33px;
      background: #daff00;
      margin-top: 8px;
    }

    .optfor {
      color: #daff00;
      font-weight: 600;
    }
  }

  .register-input {
    width: 100%;
    height: 259px;
    background: url("https://cdn.bubbleplan.cn/static/login/Vector6522x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 40px 35px;
    padding-bottom: 0;
    &-imgew {
      width: 29px;
      height: 29px;
      margin: 0 10px;
    }

    :deep(.el-input__inner) {
      width: 100%;
      height: 58px;
      background: url("https://cdn.bubbleplan.cn/static/login/Vector5602x.png")
        no-repeat;
      background-size: 100% 100%;
      line-height: 58px;
      border: none;
      font-size: 20px;
      color: #e6d9d9;
      font-weight: Bold;
      padding-left: 50px;
    }
    :deep(.el-input__inner)::placeholder {
      font-size: 20px;
      color: #e6d9d9;
      font-weight: Bold;
    }
    :deep(.el-input--small) {
      font-size: 20px;
    }
    :deep(.el-input--small .el-input__icon) {
      line-height: 53px;
    }
    .buttonwerw {
      width: 151px;
      height: 69px;
      background: url("https://cdn.bubbleplan.cn/static/login/Frame96192x.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 69px;
      margin-left: 18px;
      color: #f1eae2;
      font-size: 20px;
      font-weight: Bold;
      border: none;
    }
    :deep(.el-button) {
      width: 151px;
      height: 69px;
      background: url("https://cdn.bubbleplan.cn/static/login/Frame96192x.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 49px;
      margin-left: 18px;
      color: #f1eae2;
      font-size: 20px;
      font-weight: Bold;
      border: none;
    }
  }

  .register-button {
    width: 127px;
    margin: auto;
    margin-top: 43.42px;

    :deep(.el-button) {
      width: 100%;
      height: 57px;
      background: url("https://cdn.bubbleplan.cn/static/login/Vector6062x.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 37px;
      color: #f3e9d0;
      font-size: 24px;
      font-weight: bold;
      font-family: "TsangerYuMo";
      border: none;
    }

    :deep(.el-button--success.is-disabled) {
      width: 100%;
      height: 57px;
      background: url("https://cdn.bubbleplan.cn/static/login/Vector6062x.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 37px;
      color: #f3e9d0;
      font-size: 24px;
      font-weight: bold;
      font-family: "TsangerYuMo";
      border: none;
    }
  }

  .register-text {
    // margin-top: 32px;
    margin-top: 18px;
    text-align: right;
    color: #574352;
    font-size: 17px;
    font-weight: Bold;
  }
  .register-text-w:hover {
    cursor: pointer;
  }
  :deep(.el-dialog) {
    border-radius: 20px;
    background: transparent;
  }
  :deep(.el-dialog__headerbtn) {
    font-size: 20px;
  }
  .base64ImageClass {
    width: 484px;
    height: 198px;
    background: url("https://cdn.bubbleplan.cn/static/login/Group103612x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 46px 31px;

    // :deep(.el-input) {
    //   width: 280px;
    //   height: 58px;
    // }
    :deep(.el-input--small .el-input__inner) {
      width: 280px;
      height: 58px;
      background: url("https://cdn.bubbleplan.cn/static/login/Vector5602x.png")
        no-repeat;
      background-size: 100% 100%;
      border: none;
      color: #574352;
      font-size: 20px;
      font-weight: Bold;
      padding-left: 50px;
    }
    :deep(.el-input__inner)::placeholder {
      color: #574352;
      font-size: 20px;
      font-weight: Bold;
    }
    &-img {
      width: 107px;
      height: 48px;
    }
    &-button:nth-child(2) {
      width: 140px;
      height: 56px;
      background: url("https://cdn.bubbleplan.cn/static/login/Vector6072x.png")
        no-repeat;
      background-size: 100% 100%;
      line-height: 56px;
      color: #f3e9d0;
      font-family: "TsangerYuMo";
      font-size: 24px;
      font-weight: bold;
      text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
        2px 2px 0 #000000;
      /* 模拟描边效果 */
      text-align: center;
    }
    &-button {
      width: 140px;
      height: 56px;
      background: url("https://cdn.bubbleplan.cn/static/login/Vec123tor6062x.png")
        no-repeat;
      background-size: 100% 100%;
      line-height: 56px;
      color: #f3e9d0;
      font-family: "TsangerYuMo";
      font-size: 24px;
      font-weight: bold;
      text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
        2px 2px 0 #000000;
      /* 模拟描边效果 */
      text-align: center;
    }
    &-refresh:hover {
      cursor: pointer;
    }
    &-button:hover {
      cursor: pointer;
    }
  }
  &-agreement {
    color: #574352;
    font-size: 17px;
    margin-top: 14px;
    font-weight: bold;
    &-img {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }
    &-img:hover {
      cursor: pointer;
    }
    &-text {
      color: #de56c8;
      font-size: 17px;
    }
    &-text:hover {
      cursor: pointer;
    }
  }
}
</style>